.content {
    padding: 16px;
    font-family: arial, sans-serif;
    margin-bottom: 100px;
    position: relative;
}

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

td,
th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

tr:nth-child(even) {
    background-color: #dddddd;
}

input[type="file"] {
    display: none;
}

input[type=text],
select {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}



button,
.custom-file-upload {
    width: 100%;
    background-color: #bd4c33;
    color: white;
    font-size: 30px;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.custom-file-upload {
    font-size: 23px;
    padding: 4px 4px;
    margin: 2px 0;
}

button:hover,
.custom-file-upload:hover {
    background-color: #993f2b;
}


.switch {
    position: relative;
    display: inline-block;
    width: 55px;
    height: 27px;
    margin-left: 3px;
    margin-right: 3px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #993f2b;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
}

input:checked+.slider {
    background-color: #bd4c33;
}

input:focus+.slider {
    box-shadow: 0 0 1px #bd4c33;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

p{
    font-size: 23px;
}

.inputText{
    font-size: 17px;
}

#pano {
    float: left;
    height: 100%;
    width: 50%;
  }
  